import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import MainScreen from "../screens/MainScreen";
import AboutScreen from "../screens/AboutScreen";
import ContactScreen from "../screens/ContactScreen";
import SideNav from "./SideNav";
import ProjectsScreen from "../screens/ProjectsScreen";

const RootNavigator = (props) => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SideNav />} exact>
          <Route path="/" element={<MainScreen />} exact />
          <Route path="/about" element={<AboutScreen />} exact />
          <Route path="/projects" element={<ProjectsScreen />} exact />
          <Route path="/contact" element={<ContactScreen />} exact />
        </Route>
      </Routes>
    </Router>
  );
};

const styles = {};

export default RootNavigator;
