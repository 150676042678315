import React from "react";
import { useWindowSize } from "../helpers/Utils";

const AboutScreen = (props) => {
  const [width, height] = useWindowSize();

  return (
    <div style={styles.root}>
      <div style={styles.mainColumn}>
        <div style={styles.header}>ABOUT ME</div>
        <div
          style={{
            ...styles.description,
            ...{
              fontSize: width > 900 ? 20 : 16,
            },
          }}
        >
          <p>
            I've been creating software for over 10 years, starting with many
            personal projects and eventually branching into the professional
            world. I thrive as a lone-wolf, routinely taking on
            conceptualizaton, design and implementation phases of software
            development personally in succession. I have unique perspective and
            experience in both the Aviaiton and Music industries, and thus
            creating software specific to these fields is a specialty of mine. I
            have a small company known as Avrologic creating software for the
            Aviaiton industry.
          </p>
          <p>
            Apart from software engineering, I'm both a commercially-licenced
            pilot and a multi-instrumentalist with audio engineering experience.
          </p>
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "d Diam",
  },
  header: {
    fontSize: "min(max(48px, 8vw),  90px)",
    mixBlendMode: "overlay",
    lineHeight: "0.8",
  },
  mainColumn: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "min(max(250px, 48vw), 700px)",
  },
  description: {
    marginTop: 25,
    fontFamily:
      '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
    maxWidth: 700,
  },
};

export default AboutScreen;
