import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Heading, IconButton } from "evergreen-ui";

const RegularIconButton = (props) => {
  const buttonRef = useRef();

  const [isHovered, setIsHovered] = useState(false);

  function MouseOver(event) {
    buttonRef.current.style.opacity = 0.1;
    setIsHovered(true);
  }

  function MouseOut(event) {
    buttonRef.current.style.opacity = 0;
    setIsHovered(false);
  }

  var color = "#FFF";
  var scale = 30;
  var size = "xs";
  var fontSize = 16;

  if (props.color) {
    color = props.color;
  }

  if (props.scale) {
    scale = props.scale;
  }

  if (props.size) {
    size = props.size;
  }

  if (props.fontSize) {
    fontSize = props.fontSize;
  }

  return (
    <div
      style={{
        ...styles.iconButton,
        ...{
          fontSize: fontSize,
          width: scale,
          height: scale,
          pointerEvents: props.disabled ? "none" : "auto",
          opacity: props.disabled ? 0.4 : 1,
          cursor: props.disabled ? "default" : "pointer",
        },
        ...props.style,
      }}
      onMouseOver={MouseOver}
      onMouseOut={MouseOut}
      onClick={props.onClick}
      className={props.className}
    >
      {props.labelLeft ? (
        <div style={styles.labelLeftContainer}>
          <Heading
            size={100}
            style={{ color: props.labelColor ? props.labelColor : null }}
          >
            {props.labelLeft}
          </Heading>
        </div>
      ) : null}
      <div
        style={{ ...styles.iconContainer, ...{ width: scale, height: scale } }}
      >
        <div
          style={{
            ...styles.iconBackground,
            width: isHovered ? scale : 1,
            height: isHovered ? scale : 1,
            backgroundColor: color,
          }}
          ref={buttonRef}
        ></div>
        {props.evergreen ? (
          <>{props.icon}</>
        ) : (
          <FontAwesomeIcon
            style={{ ...styles.icon, ...props.iconStyle }}
            color={color}
            size={size}
            icon={props.icon}
          />
        )}
      </div>
      {props.labelRight ? (
        <div style={styles.labelRightContainer}>
          <Heading size={100}>{props.labelRight}</Heading>
        </div>
      ) : null}
    </div>
  );
};

const styles = {
  iconButton: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  iconBackground: {
    transition: "all 0.15s",
    borderRadius: 90,
    opacity: 0.2,
    position: "absolute",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  labelLeftContainer: {
    marginRight: 10,
    whiteSpace: "no-wrap",
  },
  labelRightContainer: {
    marginLeft: 10,
    whiteSpace: "no-wrap",
  },
};

export default RegularIconButton;
