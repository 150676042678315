import React from "react";
import { useWindowSize } from "../helpers/Utils";

const ContactScreen = (props) => {
  const [width, height] = useWindowSize();

  return (
    <div style={styles.root}>
      <div style={styles.mainColumn}>
        <div style={styles.header}>CONTACT</div>
        <div
          style={{
            ...styles.contactRow,
            ...{
              marginTop: 25,
            },
          }}
        >
          +1 (403) 330-7418
        </div>
        <div style={styles.contactRow}>ced.h.mcd@gmail.com</div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "d Diam",
  },
  header: {
    fontSize: "min(max(48px, 8vw),  90px)",
    mixBlendMode: "overlay",
    lineHeight: "0.8",
  },
  contactRow: {
    fontSize: "min(max(20px, 4vw),  36px)",
    fontFamily:
      '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
  },
  mainColumn: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "min(max(250px, 48vw), 700px)",
  },
  description: {
    marginTop: 25,
    maxWidth: 700,
  },
};

export default ContactScreen;
