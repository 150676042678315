import logo from "./logo.svg";
import "./App.css";
import { Helmet } from "react-helmet";
import RootNavigator from "./navigation/RootNavigator";
import BackgroundImage from "./assets/images/raster/background.png";

function App() {
  return (
    <div style={styles.screen}>
      <Helmet>
        <title>Cedric McDonald</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta
          name="title"
          content="Cedric McDonald"
          data-react-helmet="true"
        ></meta>
      </Helmet>
      <RootNavigator />
    </div>
  );
}

const styles = {
  screen: {
    display: "flex",
    flex: 1,
    height: "100%",
    color: "#FFF",
  },
};

export default App;
