import React, { useState } from "react";
import { useWindowSize } from "../helpers/Utils";
import Iceman from "../assets/images/raster/iceman.png";
import Iceman2 from "../assets/images/raster/iceman2.png";
import Iceman3 from "../assets/images/raster/iceman3.png";
import Iceman4 from "../assets/images/raster/iceman4.png";
import Iceman5 from "../assets/images/raster/iceman5.png";
import Iceman6 from "../assets/images/raster/iceman6.png";
import Iceman7 from "../assets/images/raster/iceman7.png";
import FltSched from "../assets/images/raster/fltsched.png";
import FltSched2 from "../assets/images/raster/fltsched2.png";
import FltSched3 from "../assets/images/raster/fltsched3.png";
import AeroNet from "../assets/images/raster/aeronet.png";
import AeroNet2 from "../assets/images/raster/aeronet2.png";
import AeroNet3 from "../assets/images/raster/aeronet3.png";
import AeroNet4 from "../assets/images/raster/aeronet4.png";
import Frequentory from "../assets/images/raster/frequentory.png";
import Frequentory2 from "../assets/images/raster/frequentory2.png";
import Frequentory3 from "../assets/images/raster/frequentory3.png";
import BachmanArchives from "../assets/images/raster/bachman_archives.png";
import BachmanArchives2 from "../assets/images/raster/bachman_archives2.png";
import { Portal } from "evergreen-ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import RegularIconButton from "../components/UI/RegularIconButton";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ListItem = (props) => {
  const [hovered, setHovered] = useState(false);

  const [portalVisible, setPortalVisible] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => {
          setHovered(true);
        }}
        onMouseLeave={() => {
          setHovered(false);
        }}
        onClick={() => {
          setPortalVisible(true);
        }}
        style={{
          ...styles.projectThumbnail,
          ...{
            backgroundColor: hovered ? "rgba(255,255,255,0.1)" : "transparent",
          },
        }}
      >
        <img src={props.images[0].original} style={{ width: "100%" }} />
        <div style={styles.projectThumbnailMetaContainer}>
          <div
            style={{
              fontSize: 24,
            }}
          >
            {props.title}
          </div>
          <div
            style={{
              fontSize: 16,
            }}
          >
            {props.subtitle}
          </div>
        </div>
      </div>
      {portalVisible ? (
        <Portal>
          <div
            style={styles.portalWrapper}
            onClick={() => {
              setPortalVisible(false);
            }}
          >
            <div
              style={styles.portalWindow}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div style={styles.portalButtonsContainer}>
                <RegularIconButton
                  onClick={() => {
                    setPortalVisible(false);
                  }}
                  icon={faXmark}
                  fontSize={24}
                  scale={30}
                />
              </div>
              <div style={styles.portalContentContainer}>
                <div style={{}}>
                  <ReactImageGallery
                    items={props.images}
                    showPlayButton={false}
                  />
                </div>
                <div style={styles.portalText}>
                  <h2>{props.title}</h2>
                  <p>-</p>
                  <p>{props.description}</p>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      ) : null}
    </>
  );
};

const ProjectsScreen = (props) => {
  const [width, height] = useWindowSize();

  return (
    <div style={styles.root}>
      <div style={styles.mainColumn}>
        <div style={styles.header}>PROJECTS</div>
        <div
          style={{
            ...styles.description,
            ...{
              fontSize: width > 900 ? 20 : 16,
            },
          }}
        >
          <ListItem
            images={[
              {
                original: Iceman,
                thumbnail: Iceman,
              },
              {
                original: Iceman2,
                thumbnail: Iceman2,
              },
              {
                original: Iceman3,
                thumbnail: Iceman3,
              },
              {
                original: Iceman4,
                thumbnail: Iceman4,
              },
              {
                original: Iceman5,
                thumbnail: Iceman5,
              },
              {
                original: Iceman6,
                thumbnail: Iceman6,
              },
              {
                original: Iceman7,
                thumbnail: Iceman7,
              },
            ]}
            title="Iceman"
            subtitle="Aircraft De-Icing Management Suite"
            description={
              <>
                Iceman is an aircraft de-icing management suite for use by
                de-icing providers operating and small to mid-sized aerodromes.
                Iceman provides tools to both digitize and streamline the
                de-icing record keeping process, as well as tools to support
                ground staff in the process of de-icing itself. With ample
                experience in the industry, I was positioned well to be able to
                create this piece of software, intimately familiar with the
                day-to-day experience of the boots-on-the-ground airport staff
                and areas that could be improved upon.
                <br />
                <br /> Website:{" "}
                <a href="https://iceman.app" target="_blank">
                  iceman.app
                </a>
              </>
            }
          />
          <ListItem
            images={[
              {
                original: FltSched,
                thumbnail: FltSched,
              },
              {
                original: FltSched2,
                thumbnail: FltSched2,
              },
              {
                original: FltSched3,
                thumbnail: FltSched3,
              },
            ]}
            title="FltSched"
            subtitle="Charter Flight Scheduling Software"
            description={
              <>
                For an aircraft fractional ownership company based in
                Lethbridge, Alberta, known as "AeroNet Aviation", I was tasked
                with creating a flight scheduling software suite that would both
                allow clients to manage their flight schedules via a mobile app
                for iOS and Android, and allow administrative staff to manage
                aircraft/crew schedules, client flight requests, and more via a
                flexible web interface.
                <br />
                <br />
                iOS App Store Link:{" "}
                <a
                  href="https://apps.apple.com/ca/app/aeronet-aviation/id6444300123"
                  target="_blank"
                >
                  AeroNet Aviation
                </a>
              </>
            }
          />
          <ListItem
            images={[
              {
                original: AeroNet,
                thumbnail: AeroNet,
              },
              {
                original: AeroNet2,
                thumbnail: AeroNet2,
              },
              {
                original: AeroNet3,
                thumbnail: AeroNet3,
              },
              {
                original: AeroNet4,
                thumbnail: AeroNet4,
              },
            ]}
            title="AeroNet Aviation"
            subtitle="Company Website"
            description={
              <>
                I created the website for fractional aircraft ownership company
                "AeroNet Aviation" based in Lethbridge, Alberta. I was
                responsible for everything from designing the website, creating
                branding elements such as the company logo and (some of the)
                aircraft photography, and custom-coding the website using
                ReactJS, employing various SEO techniques.
                <br />
                <br />
                Website:{" "}
                <a href="https://aeronetaviation.ca/" target="_blank">
                  AeroNet Aviation
                </a>
              </>
            }
          />
          <ListItem
            images={[
              {
                original: Frequentory,
                thumbnail: Frequentory,
              },

              {
                original: Frequentory2,
                thumbnail: Frequentory2,
              },
              {
                original: Frequentory3,
                thumbnail: Frequentory3,
              },
            ]}
            title="Frequentory"
            subtitle="Asset Management Application"
            description={
              <>
                Frequentory is a cloud-based solution designed to flexibly
                organize hundreds or thousands of items across multiple
                locations, all with unique high-resolution images and
                miscellaneous files attached.
                <br />
                <br />
                Website:{" "}
                <a href="https://app.frequentory.com/" target="_blank">
                  app.frequentory.com
                </a>
              </>
            }
          />
          <ListItem
            images={[
              {
                original: BachmanArchives,
                thumbnail: BachmanArchives,
              },
              {
                original: BachmanArchives2,
                thumbnail: BachmanArchives2,
              },
            ]}
            title="Bachman Archives"
            subtitle="Multimedia Archival Software"
            description={
              <>
                Bachman Archives, designed for Randy Bachman of{" "}
                <i>The Guess Who</i> and <i>Bachman-Turner Overdrive</i>, is a
                unique multimedia archival tool built to serve as a single
                source of truth for Bachman's life's work. This software is also
                capable of public streaming, with granular analytics data
                available.
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "d Diam",
  },
  header: {
    fontSize: "min(max(48px, 8vw),  90px)",
    mixBlendMode: "overlay",
    lineHeight: "0.8",
  },
  mainColumn: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
  },
  description: {
    marginTop: 25,
    fontFamily:
      '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
    maxWidth: 1200,
    width: "68vw",
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 350px)",
    // gridTemplate: "repeat(4, 1fr) / repeat(2, 1fr)",
    // gridAutoRows: "minmax(100px, auto)",  grid-template-columns: 1fr 3fr;
  },
  projectThumbnail: {
    cursor: "pointer",
    display: "flex",
    flex: 1,
    maxWidth: 400,
    minWidth: 250,
    padding: 10,
    flexDirection: "column",
    transition: "background-color 125ms ease-in-out",
  },
  projectThumbnailMetaContainer: {
    width: "100%",
    marginTop: 15,
    display: "flex",
    flexDirection: "column",
  },
  portalWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  portalWindow: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
    // height: "90%",
    maxWidth: 600,
    maxHeight: "90%",
    overflow: "auto",
    backgroundColor: "rgba(255,255,255,0.3)",
    WebkitBackdropFilter: "blur(25px)",
    backdropFilter: "blur(25px)",
  },
  portalButtonsContainer: {
    width: "100%",
    display: "flex",
    height: 30,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  portalContentContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    display: "flex",
    flexDirection: "column",
  },
  portalText: {
    width: "100%",
    height: "100%",
    color: "#FFF",
    flexDirection: "column",
  },
};

export default ProjectsScreen;
