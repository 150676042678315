import React from "react";
import { useWindowSize } from "../helpers/Utils";

const MainScreen = (props) => {
  const [width, height] = useWindowSize();

  return (
    <div style={styles.root}>
      <div style={styles.mainColumn}>
        <div style={styles.logoText}>CEDRIC</div>
        <div style={styles.logoText}>MCDONALD</div>
        <div
          style={{
            ...styles.description,
            ...{
              fontSize: width > 900 ? 24 : 16,
            },
          }}
        >
          I'm a full-stack software engineer from Calgary, AB experienced in
          building dynamic web apps, mobile apps, and cloud-based services. My
          skillset also comprises graphic design, DevOps, and system/network
          administration.
        </div>
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "d Diam",
  },
  logoText: {
    fontSize: "min(max(48px, 10vw), 144px)",
    mixBlendMode: "overlay",
    lineHeight: "0.8",
  },
  mainColumn: {
    display: "flex",
    flexDirection: "column",
    padding: 10,
    width: "min(max(250px, 48vw), 700px)",
  },
  description: {
    marginTop: 25,
    fontFamily:
      '"Courier New", Courier, "Lucida Sans Typewriter", "Lucida Typewriter", monospace',
    maxWidth: 700,
  },
};

export default MainScreen;
