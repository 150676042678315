import React, { useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import { IconButton, MenuIcon, MenuClosedIcon } from "evergreen-ui";
import RegularIconButton from "../components/UI/RegularIconButton";
import { useWindowSize } from "../helpers/Utils";
import BackgroundImage from "../assets/images/raster/background.png";

const MenuItem = (props) => {
  const [hovered, setHovered] = useState(false);
  const [width, height] = useWindowSize();

  return (
    <div
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      style={styles.menuItem}
    >
      <Link
        to={props.to}
        onClick={() => {
          if (width < 700) {
            props.onClose();
          }
        }}
        style={{
          textDecoration: "none",
        }}
      >
        <div
          style={{
            ...styles.menuLabel,
            ...{
              fontSize: hovered ? 22 : 20,
            },
          }}
        >
          {props.label}
        </div>
      </Link>
    </div>
  );
};

const SideNav = (props) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [width, height] = useWindowSize();

  useEffect(() => {
    if (width > 700) {
      setMenuOpen(true);
    } else {
      setMenuOpen(false);
    }
  }, [width]);

  return (
    <div
      style={{
        ...styles.root,
        ...{
          backgroundImage: `url(${BackgroundImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        },
      }}
    >
      <div style={styles.topBar}>
        <RegularIconButton
          onClick={() => {
            setMenuOpen(!menuOpen);
          }}
          icon={
            menuOpen ? (
              <MenuClosedIcon
                style={{
                  color: "#FFF",
                }}
              />
            ) : (
              <MenuIcon
                style={{
                  color: "#FFF",
                }}
              />
            )
          }
          fontSize={16}
          scale={45}
          evergreen
        />
      </div>
      <div style={styles.contentRow}>
        <div
          style={{
            ...styles.menuContainer,
            ...{
              width: menuOpen ? 150 : 0,
            },
          }}
        >
          <div style={styles.menuItemsContainer}>
            <MenuItem
              to="/"
              label="HOME"
              onClose={() => {
                setMenuOpen(false);
              }}
            />
            <MenuItem
              to="about"
              label="ABOUT"
              onClose={() => {
                setMenuOpen(false);
              }}
            />
            <MenuItem
              to="projects"
              label="PROJECTS"
              onClose={() => {
                setMenuOpen(false);
              }}
            />
            <MenuItem
              to="contact"
              label="CONTACT"
              onClose={() => {
                setMenuOpen(false);
              }}
            />
          </div>
        </div>
        <div style={styles.contentContainer}>
          <Outlet />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          color: "#FFF",
          textAlign: "center",
          paddingBottom: 5,
        }}
      >
        © 2024 Cedric McDonald. All rights reserved.
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%",
    minHeight: "100dvh",
    backgroundColor: "rgb(7,24,36)",
    overflowX: "hidden",
  },
  menuContainer: {
    display: "flex",
    transition: "width 225ms ease-in-out",
    flexDirection: "column",
    overflow: "hidden",
    maxHeight: "100vh",
  },
  menuItem: {
    width: "100%",
    height: 25,
    display: "flex",
    padding: 10,
    marginLeft: 25,
  },
  menuLabel: {
    color: "#FFF",
    transition: "font-size 225ms ease-in-out",
  },
  topBar: {
    height: 30,
    padding: 10,
    display: "flex",
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
  },
  menuItemsContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
    fontFamily: "d Diam",
  },
  contentContainer: {
    display: "flex",
    flex: 1,
  },
};

export default SideNav;
